import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import apple from '../images_new/Apple.png';
import google from '../images_new/Google.png';
import LavieLogo2 from '../Images/image 52.png'
import LogoBlack from '../images_new/logo_black.png'
const Footer = () => {
  return (
    <>
        <footer>
            <div class="footer container">
                <hr/>
                <div class="py-3"></div>
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-12">
                        <img src={LogoBlack} width="250" className='mb-3'/>
                        <p class="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit Lorem ipsum dolor sit amet consectetur adipisicing elit Lorem ipsum dolor sit amet consectetur</p>
                        <div class="d-flex text-center py-3">
                            <i class="fa-brands fa-twitter px-3"></i>
                            <i class="fa-brands fa-square-instagram px-3"></i>
                            <i class="fa-brands fa-linkedin px-3"></i>
                            <i class="fa-brands fa-youtube px-3"></i>
                            <i class="fa-brands fa-square-whatsapp px-3"></i>
                            <i class="fa-brands fa-behance px-3"></i>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="d-flex justify-content-center">
                            <div class="footer-menu">
                                <h6>Menu</h6>
                                <p>Home</p>
                                <p>Create Event</p>
                                <p>Blog</p>
                                <p>Posts</p>
                                <p>Hehe</p>
                            </div>
                            <div class="footer-menu">
                                <h6>Legal</h6>
                                <p>Privacy Policy</p>
                                <p>Terms of services</p>
                                <p>Support</p>
                                <p>Report</p>
                                <p>Contact</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-12 col-12">
                        <img src={apple} class="w-75 mx-auto" alt="Apple store"/>
                        <img src={google} class="w-75 mx-auto" alt="Google store"/>
                    </div>
                </div>
                <hr/>
                <div class="text-center">
                    <p>© 2024 LaVie Connect. </p>
                    <p>All Rights Reserved. </p>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Footer
