import React, { useEffect, useRef } from 'react';
import intlTelInput from 'intl-tel-input'; // Import the intl-tel-input library
import 'intl-tel-input/build/css/intlTelInput.css'; // Import the CSS for styling
import Image from '../Images/Login.png'; // Correct the image import
import google from '../images_new/flat-color-icons_google.png';
import apple from '../images_new/apple_small.png';
import { TbBrandApple } from "react-icons/tb";
import { FaGofore } from "react-icons/fa";
import {Link} from 'react-router-dom'
import { IoChevronBack } from "react-icons/io5";
const Login = () => {

  // Create a reference for the phone input field
  const phoneInputRef = useRef(null);

  useEffect(() => {
    // Initialize the intl-tel-input plugin on the phone input field
    if (phoneInputRef.current) {
      intlTelInput(phoneInputRef.current, {
        initialCountry: "gb", // Set initial country (e.g., "in" for India)
        separateDialCode: true, // Display the dial code separately
        preferredCountries: ["gb"], // List of preferred countries
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js" // Script for formatting
      });
    }
  }, []); // Empty dependency array ensures this runs once when the component mounts

  return (
    <div className='container-fluid vh-100 p-0 m-0 d-flex '>
      <div className='row w-100 login-left'>
        <img  className='img-fluid loginimage m-0 p-0'
          style={{ height: '100vh', width: '50vw', objectFit: 'cover' }}
          src={Image}
          alt="Login Background"
        />
      </div>
      <div className='col-md-6'>
        <div className='d-flex flex-column py-5'>
          <div className='top-right px-3'>
            <p className='text-gray'>STEP 01/03</p>
            <p className='text-black'>Sign in</p>
          </div>
          <div className='text-center py-5'>
            <h3 className='mb-3'>Sign in to your account</h3>
            <p>Enter your number to signin to your account</p>
          </div>
          <div className='w-50 mx-auto'>
            <form>
                <div class="form-group mb-4">
                    {/* <input type="text" id="mobile_code" class="form-control p-2" placeholder="Phone Number" name="name"/> */}
                    <div className="mb-3">
                      <label style={{marginTop:"10px"}} htmlFor="Phone" className="label">Phone Number</label><br />
                      <input
                        style={{width : "25vw"}}
                        type="tel"
                        ref={phoneInputRef}
                        className="form-control"
                        id="Phone"
                        aria-describedby="phoneHelp"
                      />
                    </div>
                </div>
                <button type='submit' className='w-100 otp-btn btn'>Get OTP</button>
                <p className='text-center py-3'> OR </p>
                <button className='btn bg-white border rounded-2 d-flex justify-content-start w-100 mx-auto mb-3'>
                  <span>
                    <img src={google} width={30} />
                  </span>
                  <p className='px-5'>Sign in using Google</p>
                </button>
                <button className='btn bg-white border rounded-2 d-flex justify-content-start w-100 mx-auto'>
                  <span>
                    <img src={apple} width={30} />
                  </span>
                  <p className='px-5'>Sign in using Apple</p>
                </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;