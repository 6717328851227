import React from 'react'
import wrongotp from '../Images/Group 38554.png'
import signupwrong from '../Images/Sign Up OTP.png'
import Image from '../Images/Login.png';
<style>

</style>
const WrongOTP = () => {
  return (
    <div className='vh-100 class'>
      <div className='firstimage'><img height={700} width={600} src={Image} alt="" srcset="" />
      <img height={700} width={600}  src={signupwrong} alt="" srcset="" />
      </div>
      <div  className='secondimage'>
        <img src={wrongotp} alt="" srcset="" /> 
        </div>
    </div>
  )
}

export default WrongOTP
