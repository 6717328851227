import React from 'react'
import Image from '../Images/Login.png';
import { MdArrowBackIos } from "react-icons/md";
const PersonalDetaills = () => {
  return (
    <div className='container-fluid  d-flex align-items-center prsonaldetailsmaindiv'>
        <div className='row  personaldetailsimage' >
            
             <img style={{height:"100vh", width:"100%", }} src={Image}alt='' />
             </div>
        <div className='col-md-6 d-flex  flex-column align-items-center me-3 personaldetailslowerpage' > 
        <div className='interesttop'><button className='upperbuttons'><MdArrowBackIos style={{marginBottom:'5px'}} />Back</button>
<div>
  <p style={{color:'grey'}}>step 06/10</p>
<button className='upperbuttons'>Skip</button>
</div>
</div>
       {/* upper section emds her */}
       <div className='enterdetails'>
       <h3 style={{marginRight:"50px"}}> Personal Details</h3>
       <p style={{fontSize:"15px"}}>Enter your personal details to create your account ?</p>
       </div>
        {/* upper heading secrion ends here */}
        {/* forms div starts from here */}
        <div className='personaldetailsform'>
        <form className='personaldetailsform1' style={{marginRight:"150px"}}  >
{/* first name label */}
<label className='Firstname' htmlFor="">First Name</label><br />
<input className='interestform'style={{borderRadius:"10px"}} type="text" value="" placeholder='Enter Your first name' /> <br />
<br />
{/* last name label */}
<label className='Lastname' htmlFor="">Last name</label> <br />
<input className='interestform' style={{borderRadius:"10px"}} type="Text" value=""placeholder='Enter Your last name' /><br />
<br />
{/* email label */}
<label className='Email' htmlFor="">Email</label> <br />
<input className='interestform' style={{borderRadius:"10px"}} type="Emai" value="" placeholder='example@gmail.com' /> <br />
<br />
{/* gender label */}
<label className='personalgender' htmlFor="">Gender</label> <br />
<input className='interestform' style={{borderRadius:"10px"}}type="text" value="" placeholder='Enter Your gender' /><br />
<br />
<br />
{/* height label */}
<label className='height' htmlFor="">Height</label> <br />
<input className='interestform' style={{borderRadius:"10px"}}type="text" value="" placeholder='Enter Your height' /><br />
<br />
{/* height gender checkbox */}

<div className='personaldetailsheightgrender'>
<div className='personaldetailsheightgrender1'>
<input id='gender' type="checkbox" />
<label className='mt-2 personaldetailscheckbox' htmlFor="gender"> <center><p style={{marginLeft:"10px", fontSize:"13px", textAlign:"center"}}>Show gender on profile ?</p></center> </label> <br /></div>
<div className='personaldetailsheightgrender2'>
<input id='height' type="checkbox" />
<label className='mt-2 personaldetailscheckbox' htmlFor="height"> <p style={{marginLeft:"10px", fontSize:"13px", textAlign:"center"}}>Show height on profile ?</p> </label>
</div>
</div>
</form>
 
        </div>
        <button className='personaldetailsnext'>Next</button>
            </div>
      
    </div>
  )
}

export default PersonalDetaills
