import React from 'react'
import lauren from '../Images/2.png'
import five from '../Images/5.png'
import Premiun from '../Images/premium.png'
import { TiSocialLinkedin } from "react-icons/ti";
import { FaTwitter } from "react-icons/fa";
import { TbBrandApple } from "react-icons/tb";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaBehance } from "react-icons/fa";
import { MdCopyright } from "react-icons/md";
import apple from '../images_new/Apple.png';
import LavieLogo2 from '../Images/image 52.png'
import google from '../images_new/Google.png'
import Footer from './Footer';
import { Link } from 'react-router-dom';
import LogoWhite from '../images_new/logo_white.png'

const Home = () => {
  return (
    <div className='container-fluid w-100'>
      <div class="main-containt wave">
          <div class="container">
              <div class="main-heading py-5">
                <div class="d-flex justify-content-between">
                      {/* <h4 class="text-white">SENIOR <span class="pink-heading">SPARKS</span></h4> */}
                      <img src={LogoWhite} width="300"/>
                      <div>
                      <Link class="pink-btn btn btn-danger" to="/login">Get Started</Link>
                      </div>
                  </div>
              </div>
              <div class="main-body py-5">
                  <div class="row">
                      <div class="col-12 col-md-4 text-white">
                          <h1>Find your match with LaVie Connect</h1>
                          <p class="text-muted my-3">With lots of unique people, you can easily find a partner without hassle. Build your next connection within few minutes.</p>
                          <button class="pink-btn btn btn-danger">Get Started</button>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-4 py-5 ">
                          <p class="text-white py-2"><b>Download our app</b></p>
                          <div>
                            <img src={apple}  width="200" alt="Apple store"/>
                            <img src={google}  width="200" alt="Google store"/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      {/* footer starts from here */}
      <Footer/>
    </div>

  )


}

export default Home
