import React from 'react'
import { IoIosSearch } from "react-icons/io";
import { GoPlus } from "react-icons/go";
import UpcomingEvents from '../Images/Upcoming Events.png'
import FrameEvents from '../Images/Frame 72.png'
import EventsOne from '../Images/Events (1).png'
import EventsTwo from '../Images/Events (2).png'
import { FaTwitter } from "react-icons/fa";
import { TiSocialLinkedin } from "react-icons/ti";
import { TbBrandApple } from "react-icons/tb";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaBehance } from "react-icons/fa";
import { MdCopyright } from "react-icons/md";
import Footer from './Footer';



const Searchandcreateevent = () => {
  return (
    <div className=' Searcheventmaindiv'>
      <div>
      <IoIosSearch size={20} className='searcheventicon'  /> 
        <input className='Serachevents' type="text" placeholder='Serach events here'/>
      </div>
      <div className='leftrightPartition'>
        <div className='leftside'>
            <button className='btn btn-info'><GoPlus size={30} />Create Event</button>
            <img className='UpcomingEvents' src={UpcomingEvents} alt="" srcset="" />
        </div>
        <div className='rightside'>
            <div className='rightsidefirstdiv'>
                <div className='rightsidefirstdiv1'><img src={FrameEvents} alt="" srcset="" /></div>
                
               <div className='rightsidefirstdiv2'><img src={EventsOne} alt="" srcset="" /></div> 
            </div>
            <div className='rightsideSeconddiv'>
                <img src={EventsTwo} alt="" srcset="" />
            </div>
        </div>
      </div>
      {/* footer starts from here */}
        <Footer/>
    </div>
  )
}

export default Searchandcreateevent
