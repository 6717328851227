import React from 'react';
import Image from '../Images/Login.png';
import { MdOutlineLock } from "react-icons/md";
import { IoChevronBack } from "react-icons/io5";
const OTPsent = () => {
  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center">
      <div className="row w-100 ">
        <div className="col-12 col-md-6 p-0 otp-left">
          <img 
            className="img-fluid" 
            style={{ height: '100vh', width: '100%', objectFit: 'cover' }} 
            src={Image} 
            alt="Login"
          />
        </div>
        <div class="col-12 col-md-6 w-50 mx-auto">
          <div className='stepsetting mt-5'>
              <span className='text-grey'> <button  className='btn btn mb-5'><IoChevronBack />Back</button></span>
              <div className='top-right px-3'>
                <p className='text-gray'>STEP 02/03</p>
                <p className='text-black'>Verify</p>
              </div>
          </div>
          <div className='w-50 mx-auto py-3' >
           <h3 className='text-left'><b>Verify Mobile Number</b></h3>
           <p className='text-left text-grey'>
              For the purpose of verification, a one-time password <br /> is sent to your mobile number.
            </p>
            <div className='mt-5 text-left'>
              <span><label>Enter Your One Time Password (OTP)</label></span>
            </div>
            <div className="d-flex justify-content-left my-3">
              {[...Array(4)].map((_, index) => (
                <input
                  key={index}
                  type="text"
                  style={{
                    borderRadius: '100%',
                    border: "none",
                    backgroundColor: "#06050517",
                    width: '40px',
                    height: '40px',
                    fontSize: '18px',
                    fontWeight : 'bold',
                    textAlign: 'center',
                    margin: '5px'
                  }}
                  maxLength="1"
                />
              ))}
            </div>
            <div className='py-3'>
              <button type='submit' className='w-100 otp-btn btn'>Save & Continue</button>
            </div>

            <div className="text-center mt-3 ">
              <p className="mb-0 ms-2 text-grey"><MdOutlineLock size={24} /> Your info is safely secured</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPsent;