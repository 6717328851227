import React from 'react'
import event from '../Images/Create Event.png'
import { FaTwitter } from "react-icons/fa";
import { TiSocialLinkedin } from "react-icons/ti";
import { TbBrandApple } from "react-icons/tb";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaBehance } from "react-icons/fa";
import { MdCopyright } from "react-icons/md";
import Footer from './Footer';
const Createevent = () => {
  return (
    <div className='container-fuid vh-100 d-flex flex-column align-items-center '>
      <div className='createeventdiv' >

        <img className='Createevent' src={event} alt="" srcset="" />
      </div>
       {/* footer starts from here */}
    <Footer/>
    </div>
  )
}

export default Createevent
