import React from 'react'
import SignupSuccess from '../Images/Pop up.png';
const Success = () => {
  return (
    <div className='d-flex vh-100 justify-content-center align-items-center'>
      <img height={300} src={SignupSuccess}  alt="" srcset="" />
    </div>
  )
}

export default Success
