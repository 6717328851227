import React, { useState } from 'react';
import { MdArrowBackIos } from "react-icons/md";
import Image from '../Images/Login.png';
import { LuUpload } from "react-icons/lu";
import { FiPlus } from "react-icons/fi";
import { RiEdit2Line } from "react-icons/ri";

const Uploadpic = () => {
    const [clicked, setClicked] = useState([false, false, false]); // An array to handle multiple clicks
    const [images, setImages] = useState([null, null, null]); // An array to store up to three images

    const handleImageUpload = (e, index) => {
        let file = e.target.files[0];
        if (file) {
            const updatedImages = [...images];
            updatedImages[index] = file;
            setImages(updatedImages);

            const updatedClicked = [...clicked];
            updatedClicked[index] = true;
            setClicked(updatedClicked);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(images);
    };

    return (
        <div className='container-fluid vh-100 d-flex align-items-center '>
            <div className='row w-100'>
                <img className='img-fluid'
                    style={{ height: '100vh', width: '50vw', objectFit: 'cover' }}
                    src={Image}
                    alt="Background"
                />
            </div>

            <div className='col-md-6 d-flex flex-column align-items-center me-5 mb-5, threeimage'>
                <div className='uploadtop'>
                    <button className='upperbuttons'>
                        <MdArrowBackIos style={{ marginBottom: '5px' }} />Back
                    </button>
                    <div>
                        <p>step 07/7</p>
                    <button>Skip</button>
                    </div>
                </div>
                {/* {Upper buttons div ends here} */}
                <div className='updatedetails'>
                    <h3><b>Upload your pictures!</b></h3>
                    <b className='selectpic'>(Select up to 3)</b>
                    <div className='chooseyourpic'>
                    <p >choose your pictures or videos</p></div>
                </div>
                <div className='d-flex justify-content-center align-items-center flex-column gap-1 main'>
                    <div id='backgroundContainer'>
                        {clicked[0] && images[0] ? (
                            <div className='mainContainer'>
                                <img src={URL.createObjectURL(images[0])} alt="" className='img-preview' />
                            </div>
                        ) : (
                            <div className='mainContainer'>
                                
                                <label htmlFor="upload1" className='imagebutton2'><RiEdit2Line size={50}/></label>
                                <input type="file" accept="image/*" hidden id="upload1" onChange={(e) => handleImageUpload(e, 0)} />
                            </div>
                        )}

                        <div className='secondContainer'>
                            <div className='smallBoxtab'>
                                {clicked[1] && images[1] ? (
                                    <img src={URL.createObjectURL(images[1])} alt="" className='img-preview' />
                                ) : (
                                    <>
                                        
                                        <label htmlFor="upload2" className=' imagebutton 'style={{fontSize:'10px'}} ><FiPlus size={50} /></label>
                                        <input type="file" accept="image/*" hidden id="upload2" onChange={(e) => handleImageUpload(e, 1)} />
                                    </>
                                )}
                            </div>
                            <div className='smallBoxtab'>
                                {clicked[2] && images[2] ? (
                                    <img src={URL.createObjectURL(images[2])} alt="" className='img-preview' />
                                ) : (
                                    <>
                                       
                                        <label htmlFor="upload3" className='  imagebutton' style={{fontSize:'10px'}}><FiPlus size={50} /></label>
                                        <input type="file" accept="image/*" hidden id="upload3" onChange={(e) => handleImageUpload(e, 2)} />
                                    </>
                                )}
                            </div>
                           
                        </div>
                       
                    </div>
                    <p className='mustaddonepic' style={{width:'50%', color:'black',}}>You must add one picture. You can add upto to 3 pictures or videos to create account.Video limit is 10s to 30s</p>
                    <div>
                    <button className='btn btn nextbutton' style={{ width: '400px' }} onClick={handleSubmit}>Next</button>
                </div>
                </div>
               
            </div>
        </div>
    );
}

export default Uploadpic;