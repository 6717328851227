import React from 'react'
import emie from '../Images/Emie.png'
const Callpage = () => {
  return (
    <div>
      <img className='emie' src={emie} alt="" srcset="" />
    </div>
  )
}

export default Callpage
